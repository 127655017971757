import React from "react"
import PropTypes from "prop-types"

const LayoutRoot = ({ children }) => {

	return (
		<div id="page">
			{children}
		</div>
	)
}

LayoutRoot.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutRoot
